<script>
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";
// import multiselect from "vue-multiselect";
export default {
  components: {},
  data() {
    return {
      formData: {
        peserta: "",
        idBatch: this.$route.params.idbatch,
        idjadwal: this.$route.params.id,
        jabatan: {},
        kode_job: {},
      },
      id: this.$route.params.id,
      option_peserta: [],
      option_jabatan: [],
      option_jobCode: [],
    };
  },
  mounted() {
    this.getJabatan();
    this.filterData = _.debounce(this.onSearchPeserta.bind(this), 500);
  },
  methods: {
    submitForm() {
      if (!this.validateForm()) {
        return; // Do not submit the form if validation fails
      }
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      const authToken = localStorage.access_token;
      let payload = { ...this.formData };
      payload.jabatan = this.formData.jabatan?.nama_jabatan ?? "";
      payload.kode_job = this.formData.kode_job?.kode_job ?? "";
      payload.nama_job = this.formData.kode_job?.nama_job ?? "";
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            `master/jadwal-pelaksanaan/${this.$route.params.id}/setup-jadwal/batch/jadwal-peserta`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Halaman akan dimuat ulang.",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              window.location.reload();
            }
          });
        })
        .catch((error) => {
          Swal.close();

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data,
          });
        });
    },
    resetForm() {
      this.formData.nama_batch = "";
      this.formData.waktu_mulai = "";
      this.formData.waktu_selesai = "";
    },
    validateForm() {
      if (this.formData.peserta.length < 1) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan Pilih Peserta",
        });

        return false;
      }

      return true; // Form is valid
    },

    onSearchPeserta(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPeserta(loading, search, this);
      }
    },
    searchPeserta(loading, search) {
      const authToken = localStorage.access_token;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            `get-user-cermat?search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.option_peserta = response.data.data;
          this.option_peserta.map(function (x) {
            return (x.item_data = x.nama + " " + x.u_nip + " ");
          });
          loading(false);
        })
        .catch(() => {});
      console.log();
      console.log(search);
    },

    async searchJabatan(search, loading) {
      if (search.length) {
        loading(true);
        await this.getJabatan(loading, search);
      }
    },
    getJabatan(loading, search) {
      const authToken = localStorage.access_token;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "referensi/jabatan-kompetensi/all",
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
            params: {
              search: search,
              limit: 10,
            },
          }
        )
        .then((response) => {
          this.option_jabatan = response.data.data;
          loading(false);
        })
        .catch(() => {});
    },
    async searchJobCode(search, loading) {
      if (search.length) {
        loading(true);
        await this.getJobCode(
          this.formData.jabatan?.nama_jabatan,
          loading,
          search
        );
      }
    },
    async getJobCode(jabatan, loading, search) {
      await axios({
        method: "get",
        url: `${process.env.VUE_APP_BACKEND_URL_VERSION}master/jabatan-kompetensi/job-code/${jabatan}`,
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
        params: {
          search: search,
          limit: 10,
        },
      })
        .then((response) => {
          this.option_jobCode = response.data.data;
          loading(false);
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
    },
    myFilter: (option, label, search) => {
      let temp = search.toLowerCase();

      return (
        option.u_nip.toLowerCase().indexOf(temp) > -1 ||
        option.lastname.toLowerCase().indexOf(temp) > -1
      );
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here if needed */
</style>

<template>
  <div class="container">
    <form @submit.prevent="submitForm" class="needs-validation" novalidate>
      <!-- <Multiselect
        v-model="multiValue"
        :options="source"
        :multiple="true"
        :close-on-select="true"
        placeholder="Pick some"
        label="name"
        track-by="name"
      /> -->

      <div class="mb-3">
        <label for="email" class="form-label">Cari Peserta</label>
        <v-select
          :options="option_peserta"
          label="item_data"
          searchable="true"
          @search="filterData"
          placeholder="Cari Peserta"
          multiple
          v-model="formData.peserta"
        >
          <template #selected-option="{ nama, jabatan }">
            <div style="display: flex; align-items: baseline">
              <p>{{ nama }}-{{ jabatan }}</p>
            </div>
          </template>
          <template v-slot:option="option">
            <div class="d-center">{{ option.nama }}-{{ option.u_nip }}</div>
          </template>
        </v-select>
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Jabatan Asesmen</label>
        <v-select
          :options="option_jabatan"
          :clearable="false"
          v-model="formData.jabatan"
          :placeholder="'Pilih Jabatan'"
          label="nama_jabatan"
          @search="searchJabatan"
          @option:selected="
            getJobCode(formData.jabatan?.nama_jabatan);
            formData.kode_job = {};
          "
          @option:deselected="getJobCode('')"
        >
          <template #option="{ nama_jabatan }">
            {{ nama_jabatan }}
          </template>
        </v-select>
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Job Code</label>
        <v-select
          :options="option_jobCode"
          label="job"
          :clearable="false"
          v-model="formData.kode_job"
          placeholder="Pilih Job Code"
          @search="searchJobCode"
        >
          <template #option="{ job }">
            {{ job }}
          </template>
        </v-select>
      </div>
      <div class="mb-3">
        <button type="submit" class="btn btn-primary" style="float: right">
          Submit
        </button>
      </div>
    </form>
  </div>
</template>
