<template>
  <div class="row">
    <div class="col-12 mb-2">
      <button class="btn btn-success btn-sm" @click="downloadTemplate()">
        <i class="fa fa-download me-1"></i> Download Template
      </button>
    </div>
    <form @submit.prevent="submitForm" class="needs-validation" novalidate>
      <div class="col-12 mb-3">
        <label for="file" class="form-label">Select File</label>
        <input
          type="file"
          ref="fileInput"
          class="form-control"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          required
        />
        <div class="invalid-feedback">Please select a valid file.</div>
      </div>

      <div class="mb-3">
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  emits: ["submit"],
  data() {
    return {
      idbatch: this.$route.params.idbatch,
    };
  },
  methods: {
    async downloadTemplate() {
      let url =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "download-template/peserta-batch";
      window.open(url, "_blank");
    },
    submitForm() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      const authToken = localStorage.access_token;
      const fileInput = this.$refs.fileInput;
      const file = fileInput.files[0];
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            `master/jadwal-pelaksanaan/${this.$route.params.id}/setup-jadwal/batch/jadwal-peserta/${this.idbatch}/import-peserta`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log("Response:", response.data.data);
          let response_fix = response.data.data;
          let text = `Sukses: ${response_fix.success} Gagal: ${response_fix.failed}`;
          if (response_fix?.lineFailed?.length) {
            let line = response_fix.lineFailed.toString();
            console.log(line)
            text += `<br/> Gagal pada baris: ${line}`;
          }
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            html: text,
            timer: 3000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              // window.location.reload();
              this.$emit("submit");
            }
          });
        })
        .catch((error) => {
          // Handle errors
          console.error("Error:", error);
        });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here if needed */
</style>
