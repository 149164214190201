<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import ModalAddPeserta from "./modal-add-peserta.vue";
import ModalImportPeserta from "./modal-import-peserta.vue";
import permissionService from "../../../../../../../helpers/permissionService";

export default {
  page: {
    title: "Penjadwalan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ModalImportPeserta,
    ModalAddPeserta,
  },
  props: {
    isView: Boolean,
  },
  data() {
    let sortOrders = []; // select sortOrder

    let columns = [
      {
        width: "100px",
        label: "Batch",
        name: "idjadwal",
      },
      {
        label: "NIP",
        name: "nip",
      },
      {
        width: "auto",
        label: "Nama",
        name: "nama",
      },
      {
        width: "auto",
        label: "Jabatan",
        name: "jabatan",
      },
      {
        width: "auto",
        label: "Kompetensi",
        name: "nip",
      },
      {
        width: "auto",
        label: "Aksi",
        name: "-",
      },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      dataJadwal: "",
      idbatch: this.$route.params.idbatch,
      id: this.$route.params.id,
      showModalAdd: false,
      showModalImport: false,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      selectAll: false,
      selectedPeserta: [],
      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        filter_status: "all", // untuk filtering data
      },

      formData: {
        checkKompetensi: [
          // {
          //   kompetensi: [
          //     {
          //       a : 'as',
          //       checked: true,
          //     },
          //   ],
          // }
        ],
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      isWritePeserta: 0,
      isReadPeserta: 0,
      isWriteExportPeserta: 0,
      isReadExportPeserta: 0,
    };
  },
  mounted() {
    this.cekPermission("peserta", "Peserta");
    this.cekPermission("export", "Export Peserta");
    this.getDataTable();
    this.getDataById();
    console.log("props", this.$props);
  },
  methods: {
    async cekPermission(type, permission) {
      const data = await permissionService.getHakAkses(permission);
      if (type == "peserta") {
        this.isWritePeserta = data.is_write;
        this.isReadPeserta = data.is_read;
      } else if (type == "export") {
        this.isWriteExportPeserta = data.is_write;
        this.isReadExportPeseisWriteExportPeserta = data.is_read;
      }
    },
    async getDataById() {
      let self = this;
      let url =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "master/jadwal-pelaksanaan/" +
        self.id +
        "/setup-jadwal/batch/" +
        self.idbatch;

      const config = {
        method: "get",
        url: url,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      };

      try {
        const response = axios(config);
        const responseData = await response;

        if (responseData.data.meta.code === 200) {
          self.dataJadwal = responseData.data.data;
        } else {
          // throw new Error(responseData.message);
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });

        throw error;
      }
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        `master/jadwal-pelaksanaan/${this.$route.params.id}/setup-jadwal/batch/jadwal-peserta/${this.$route.params.idbatch}`
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              `master/jadwal-pelaksanaan/${this.$route.params.id}/setup-jadwal/batch/jadwal-peserta/` +
              id,
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    deleteKompetensi(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/jadwal-pelaksanaan/delete-kompetensi-peserta/" +
              id,
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    selectAllCheckbox() {
      if (this.selectAll) {
        // If "Select All" checkbox is checked, select all checkboxes in the table body
        this.selectedPeserta = this.table_data.map(
          (row) => row.idjadwal_peserta
        );
      } else {
        // If "Select All" checkbox is unchecked, deselect all checkboxes
        this.selectedPeserta = [];
      }
    },
    saveKonfigurasi() {
      console.log(this.formData);
    },
    deleteSelectedPeserta() {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              `master/jadwal-pelaksanaan/${this.$route.params.id}/setup-jadwal/batch/jadwal-peserta/delete/delete-multiple`,
            data: {
              idjadwal_peserta: self.selectedPeserta, // Pass array of selected peserta IDs to delete
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  this.selectAll = [];
                  this.selectedPeserta = [];
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    exportData() {
      let filter = {
        idJadwal: this.$route.params.id,
        idBatch: this.$route.params.idbatch,
      };
      let params = new URLSearchParams(filter).toString();
      window.open(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "export-excel/jadwal-batch-peserta?" +
          params
      );
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <router-link
          class="btn btn-danger btn-sm"
          :to="{ name: 'setup-jadwal' }"
          >Kembali</router-link
        >
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4>Setup Batch</h4>
            <table border="0">
              <tr>
                <td><b>Batch</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ dataJadwal?.nama_batch }}</td>
              </tr>
              <tr>
                <td><b>Waktu Mulai</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ dataJadwal?.waktu_mulai }}</td>
              </tr>
              <tr>
                <td><b>Waktu Selesai</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ dataJadwal?.waktu_selesai }}</td>
              </tr>
              <tr>
                <td><b>ID Jadwal</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ dataJadwal?.jadwal?.idjadwal }}</td>
              </tr>
              <tr>
                <td><b>Kode Tes</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ dataJadwal?.jadwal?.kode_tes }}</td>
              </tr>

              <tr>
                <td><b>Status</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>
                  <div v-if="dataJadwal?.jadwal?.status_jadwal == 0">
                    <div
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >
                      Batal
                    </div>
                  </div>
                  <div v-else-if="dataJadwal?.jadwal?.status_jadwal == 1">
                    <div class="badge badge-pill badge-soft-info font-size-12">
                      Aktif
                    </div>
                  </div>
                  <div v-else-if="dataJadwal?.jadwal?.status_jadwal == 2">
                    <div
                      class="badge badge-pill badge-soft-success font-size-12"
                    >
                      Selesai
                    </div>
                  </div>
                  <div v-else-if="dataJadwal?.jadwal?.status_jadwal == 3">
                    <div
                      class="badge badge-pill badge-soft-warning font-size-12"
                    >
                      Draft
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Peserta</div>
              </div>
              <div class="col-md-6" v-if="isView != true && isView != 'true'">
                <div class="text-end">
                  <button
                    v-on:click="exportData"
                    class="btn btn-sm btn-success me-2"
                    v-if="isWriteExportPeserta == 1"
                  >
                    <i class="fa fa-file-excel"></i> Export Peserta Batch
                  </button>
                  <button
                    v-on:click="showModalImport = !showModalImport"
                    class="btn btn-sm btn-success me-2"
                    v-if="isWritePeserta == 1"
                  >
                    <i class="fa fa-plus"></i> Import Peserta
                  </button>
                  <button
                    v-on:click="showModalAdd = !showModalAdd"
                    class="btn btn-sm btn-success"
                    v-if="isWritePeserta == 1"
                  >
                    <i class="fa fa-plus"></i> Tambah Peserta
                  </button>
                </div>
              </div>
              <div class="mt-2">
                <button
                  class="btn btn-danger btn-sm"
                  v-if="selectedPeserta.length > 0"
                  @click="deleteSelectedPeserta"
                >
                  Hapus Terpilih
                </button>
              </div>
              <div class="table-responsive mb-0 mt-2">
                <table class="table align-middle table-nowrap text-center">
                  <thead class="table-dark">
                    <tr>
                      <th class="align-middle">
                        <input
                          type="checkbox"
                          @change="selectAllCheckbox"
                          v-model="selectAll"
                        />
                      </th>
                      <th class="align-middle">No</th>
                      <th class="align-middle">NIP</th>
                      <th class="align-middle">Nama</th>
                      <th class="align-middle">Jabatan Asesmen</th>
                      <th class="">Kompetensi</th>
                      <th
                        class="align-middle"
                        v-if="
                          isView != true &&
                          isView != 'true' &&
                          isWritePeserta == 1
                        "
                      >
                        Aksi
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loadingTable">
                      <td colspan="6">
                        <i class="fas fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if="table_data == ''">
                      <td class="text-center" colspan="6">
                        Data Tidak Tersedia
                      </td>
                    </tr>
                    <tr
                      v-else
                      v-for="(row_data, key_data) in table_data"
                      :key="key_data"
                    >
                      <td class="text-center">
                        <!-- Checkbox for selecting peserta -->
                        <input
                          type="checkbox"
                          v-model="selectedPeserta"
                          :value="row_data.idjadwal_peserta"
                        />
                      </td>
                      <td class="text-center">
                        {{ key_data + 1 }}
                      </td>
                      <td>
                        {{ row_data.nip }}
                      </td>
                      <td>
                        {{ row_data.nama }}
                      </td>
                      <td>
                        {{ row_data.jabatan_nama }} <br />
                        {{
                          `(
                            ${row_data.kode_job || ""} -
                            ${row_data.nama_job || ""}
                          )`
                        }}
                      </td>
                      <td class="text-start">
                        <table
                          class="table table-sm table-hover table-striped mb-0"
                        >
                          <tr
                            v-for="item in row_data.status_peserta"
                            :key="item.idstatus_peserta"
                          >
                            <td>
                              {{ item.kompetensi.nama_kompetensi }} -
                              {{ item.kompetensi.level_kompetensi.nama_level }}
                              {{ `(${item.tipe_kompetensi})` }}
                            </td>
                            <td
                              style="text-align: right"
                              v-if="isWritePeserta == 1"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-danger btn-sm ms-2 float-right"
                                v-on:click="
                                  deleteKompetensi(item.idstatus_peserta)
                                "
                                v-if="isView != true && isView != 'true'"
                              >
                                <i class="fa fa-trash"></i> Hapus
                              </button>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td v-if="isView != true && isView != 'true'">
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          v-on:click="deleteData(row_data.idjadwal_peserta)"
                          v-if="isWritePeserta == 1"
                        >
                          <i class="fa fa-trash"></i> Hapus
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- <table >
                <thead>
                  <th>A</th>
                  <th>A</th>
                           <th>A</th>
                </thead>
                <tbody>
                
                </tbody>
              </table> -->
            </div>
            <!-- <pagination :pagination="pagination" @to-page="toPage"></pagination> -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal v-model="showModalAdd" hide-footer title="Tambah Peserta">
    <ModalAddPeserta v-if="showModalAdd" />
  </b-modal>
  <b-modal v-model="showModalImport" hide-footer title="Import Peserta">
    <ModalImportPeserta
      v-if="showModalImport"
      @submit="
        getDataTable();
        showModalImport = false;
      "
    />
  </b-modal>
</template>
